export const API_BASE_URL = "https://api.rakurai.io/api";
export const INTERNAL_API =
  process.env.VUE_APP_API_BASE_URL || "https://rakurai.io/xapi";
export const NETWORK = "https://api.testnet.solana.com";

export const INFLUX = {
  DB: "mainnet_metrics",
  TABLE: "graph",
  BASE_URL: "https://api.rakurai.io:8086",
  USERNAME: "mainnet_metrics_read",
  PASSWORD: "read",
};

// True TPS data and average data
export const TRUE_TPS = {
  DB: "mainnet_metrics",
  TABLE: "graph",
  CLUSTER_TABLE: "cluster_tps",
  BASE_URL: "https://api.rakurai.io:8086",
  USERNAME: "mainnet_metrics_read",
  PASSWORD: "read",
};

export const STACKED_GRAPH = {
  DB: "mainnet_metrics",
  TABLE: "graph",
  BASE_URL: "https://api.rakurai.io:8086",
  USERNAME: "mainnet_metrics_read",
  PASSWORD: "read",
};

export const NETWORK_FEE = "https://api.rakurai.io/api/networkfee";
export const NODE_LINK = "https://api.rakurai.io/api/nodelink";
